import Common from 'GroomyRoot/assets/js/common.js'
import Constants from 'Constants'
import Mouvements from "@/mixins/Mouvements.js"

var HorseMixin = {
    mixins: [Mouvements],
	methods: {
        /* Méthode générique gérant le cache */
        /* force, passé à true, permet de forcer le reload des données */
        getData: async function(caller, url, type, id, force=false) {
            const result = await this.$request.request_get_api("HorseMixin::getData::"+caller, url)
                .catch(e => {
                    return false
                })

            if(!result.retour) {
                return null
            }

            // this.setCache(type, id, result.retour)
            return result.retour
        },

        /* Retourne la liste des chevaux appartenant à cette licence, avec le nom des parents, et photo de profil */
		getHorses: async function(sexe = null) {
            let url = Constants.HORSE_LIST_URL+'?licence_key='+Constants.USER_LICENCE_KEY
            if(sexe) {
                url += "&horse_sexe="+sexe
            }
            const result = await this.$request.request_get_api("HorseMixin::getHorses::", url)
            .catch(e => {
                return null
            })

            if(result) return result.retour
            return []
        },

        /* Liste des chevaux avec actégories et croisements éventuels */
        getHorseEffectif: async function() {
            return this.$request.request_get_api("HorseMixin::getHorseEffectif::", Constants.HORSE_EFFECTIF_URL+'?licence_key='+Constants.USER_LICENCE_KEY)
            .then(res => res.retour)
            .catch(e => {
                console.error("HorseMixin::getHorseEffectif", e)
                return null
            })
        },

        /* Retourne la liste des chevaux appartenant à cette licence, avec le nom des parents, et photo de profil */
        getUserHorses: async function(search_query) {
            const url =  Constants.HORSE_USER_LIST_URL +'?licence_key='+Constants.USER_LICENCE_KEY
            return this.$request.request_get_api("HorseMixin::getHorses::", url)
                .then(res => res.retour)
                .catch(_ => null)
        },

        /* Retourne les détails du cheval à partir de son id - race, robe, sexe, age, photo de profil, nom, prénom, ... */
        getHorseById: async function(horse_id, force=false) {
            const url =  this.constructRoute(Constants.HORSE_URL, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
			return await this.getData("getHorseById", url, 'horse', horse_id, force)
        },

        /* Retourne le pédigrée d'un cheval, à partir de son id */
		getHorsePedigree: async function(horse_id, force=false) { // HORSE_PEDIGREE_URL
			const url =  this.constructRoute(Constants.HORSE_PEDIGREE_URL, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.getData("getHorsePedigree", url, 'horse_pedigree', horse_id, force)
		},

        /* Retourne le pédigrée d'un cheval, à partir de son sire */
		getHorsePedigreeBySire: async function(horse_sire, force=false) { // HORSE_PEDIGREE_URL
			const url =  this.constructRoute(Constants.HORSE_PEDIGREE_SIRE_URL, { horse_sire }) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.getData("getHorsePedigree", url, 'horse_pedigree_by_sire', horse_sire, force)
		},
		
		getHorseActualPlace: async function(horse_id) {
            const url =  this.constructRoute(Constants.HORSE_ACTUAL_PLACE_URL, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.getData("getHorseActualPlace", url, 'horse_actual_place', horse_id, false)
		},

        /* Retourne la ligne de hors_residence, pour la résidence affectée au horse_id */
        getHorseResidence: async function(horse_id, force=false) {
            const params = { 'horse_id': horse_id }
            const url = this.constructRoute(Constants.HORSE_RESIDENCE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.getData("getHorseResidence", url, 'horse_residence', horse_id, force)
        },

        getHorseMainResidence: async function(horse_id, force=false) {
            const params = { horse_id: horse_id }
            const url =  this.constructRoute(Constants.HORSE_ACTUAL_PLACE, params) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.getData("getHorseMainResidence", url, 'horse_main_residence', horse_id, force)
        },

        /* Retourne tous les mouvements enregistrés sur le horse_id */
        getHorseMouvements: async function(horse_id, force=false) {
            const url = this.constructRoute(Constants.HORSE_MOUVEMENTS_URL, {horse_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("HorseMixin::getHorseMouvements", url)
            .catch(error => {
                console.error("MouvementMixin::getHorseMouvements => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return
        },

        getInitialProvenance: async function(horse_id) {
            const params = { 'id': horse_id }
            const url = this.constructRoute(Constants.HORSE_PROVENANCE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("HorseMixin::getInitialProvenance", url)

            .catch(error => {
                console.error("MouvementMixin::getPreviousMouvement => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return
        },

        // Création manuelle
        createHorse: async function(infos) {
            let res = 0

            await this.axios.post(Constants.API_URL + "horses", {
                licence_key: Constants.USER_LICENCE_KEY,
                infos: infos
            })
            .then(response => {
                if(response.data.code_retour === 0)
                    res = response.data.retour
            })
            .catch(error => (res = 0))

            return res
        },

        devalideHorse: async function(horse_id) {
            const params = { 'horse_id': horse_id }
            const url = this.constructRoute(Constants.DELETE_HORSE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_delete_api("HorseMixin::devalideHorse", url, {}, false)

        },

        /* Ajoute une résidence principale d'un cheval */
        addHorseResidence: async function(horse_id, lieu_id) {
            const params = {
                horse_id: horse_id,
                lieu_id: lieu_id,
            }

            const url = Constants.RESIDENCE_SET_URL + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("HorseMixin::addHorseResidence", url, params, false)
        },

        /* Met à jour la résidence principale du cheval */
        updateHorseResidence: async function(horse_id, lieu_id) {
            // Simple renommage de méthode. addHorsePedigree, côté laravel, s'occupe déjà d'une éventuelle maj
            return await this.addHorseResidence(horse_id, lieu_id)
        },

        /* Update le sexe et la robe du cheval */
        updateHorseType: async function(horse_id, sexe_code, robe_code) {
            const url =  this.constructRoute(Constants.HORSE_URL, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            const params = {
                horse_sexe: sexe_code,
                horse_robe: robe_code
            }

            const response = await this.$request.request_post_api("HorseMixin::updateHorseType", url, params, false)
            .catch(e => {
                return false
            })

            if(response.code_retour === 0){
                return true
            }
        },

        /* Update tout le cheval si je suis en mode edition */
        updateHorse: async function(horse_id, params) {
            const url =  this.constructRoute(Constants.HORSE_URL, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            const response = await this.$request.request_post_api("HorseMixin::updateHorseType", url, params, false)
            .catch(e => {
                return false
            })

            if(response.code_retour === 0){
                return true
            }
        },

        updateHorseMort: async function(horse_id, params) {
            // en commentaire, en attente de l'api
            // const url =  this.constructRoute(Constants.HORSE_MORT_URL, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            // const response = await this.$request.request_post_api("HorseMixin::updateHorseMort", url, params, false)
            // .catch(e => {
            //     return false
            // })

            // if(response.code_retour === 0){
            //     return true
            // }
            return true
        },

        /* formatte dans le format attendu, pour la liste classique des chevaux */
        formatDataHorse: function(data) {
            let result = []
            // On parcourt la liste des chevaux
            for (let i = 0; i < data.length; i++) {
                let item = data[i];

                item.horse_race = item.race_label
                item.horse_robe = item.robe_label
                item.horse_sexe = item.sexe_label
                item.id = item.horse_id

                if(item.pedigree_nom_1 === 'null' || item.pedigree_nom_1 === null)
                    item.pedigree_nom_1 = 'NC'
                if(item.pedigree_nom_2 === 'null' || item.pedigree_nom_2 === null)
                    item.pedigree_nom_2 = 'NC'
    
                result.push(item)
            }

            return result
        },
        /* formatte un objet horse de l'api dans le format attendu, pour le tableau */
        formatDataHorseAjout: function(horse_obj, correspondance) {
            // La correspondance pour la clé de l'objet à manipuler
            let matching = ""
            let obj_retour = {}
            const keys_codes = ['horse_race', 'horse_robe', 'horse_sexe']
            const keys_data = {
                'horse_race': 'getRaceLabel',
                'horse_robe': 'getRobeLabel',
                'horse_sexe': 'getSexeLabel',
            }

            // On retourne tableau avec un seul objet formatté
            for (let key in correspondance) {
                matching = correspondance[key]

                // Si on a une valeur nested (dam.___ ou sire.___)
                if(matching.indexOf('.') > -1) {
                    const matching_path = matching.split('.')
                    obj_retour[key] = this.getNestedObject(horse_obj, matching_path)
                }
                else {
                    // Si la clé correspond à une valeur à récupérer en dehors de l'objet (label à partir d'un code par ex)
                    if(keys_codes.indexOf(key) > -1) {
                        const method = keys_data[key]
                        obj_retour[key] = this[method](horse_obj[matching])
                    }
                    else {
                        obj_retour[key] = horse_obj[matching]
                    }
                }
            }
            return obj_retour
		},
		
		getRaces() {
			const races = this.getConfig("horse_race")
			return races
		},

		getRobes() {
			const robes = this.getConfig("horse_robe")
			return robes
		},

		getSexes() {
			const sexes = this.getConfig("horse_sexe")
			return sexes
		},

        /* Retourne le label traduit de la race, à partir du localstorage */
        getRaceLabel: function(race_code) {
            const races = this.getRaces()
            const label = races.filter(race => race.race_code == race_code)
            if(label.length > 0)
                return label[0].race_label
            return race_code
        },
        /* Retourne le label traduit de la robe, à partir du localstorage */
        getRobeLabel: function(robe_code) {
            const robes = this.getRobes()
            const label = robes.filter(robe => robe.robe_code == robe_code)
            if(label.length > 0)
                return label[0].robe_label
            return robe_code
        },
        /* Retourne le label traduit du sexe, à partir du localstorage */
        getSexeLabel: function(sexe_code) {
            const sexes = this.getSexes()
            const label = sexes.filter(sexe => sexe.sexe_code == sexe_code)
            if(label.length > 0)
                return label[0].sexe_label
            return sexe_code
        },
        /*Retourne la liste des catégories disponibles pour la licence */
        getAllCategorie: async function() {
            const url = this.constructRoute(Constants.HORSE_ALL_CATEGORIES, {}) + "?licence_key="+Constants.USER_LICENCE_KEY

            const result = await this.$request.request_get_api("HorseMixin::getAllCategorie", url)
            .catch(error => {
                return null
			})

            if(result && result.code_retour == 0) return result.retour
            return null
		},
		
		async getHorseCategories(horse_id) {
            const url = this.constructRoute(Constants.HORSE_CATEGORIES, { horse_id }) + "?licence_key="+Constants.USER_LICENCE_KEY

            const result = await this.$request.request_get_api("HorseMixin::getHorseCategories", url)
            .catch(error => {
                return null
			})

            if(result && result.code_retour == 0) return result.retour
            return []
        },

        addHorseCategorie: async function(horse_id, categorie_id) {
            const params = {
                id: horse_id,
            }
            const post = {
                categorie_id: categorie_id,
            }

            const url = this.constructRoute(Constants.HORSE_CATEGORIE, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("HorseMixin::addHorseCategorie", url, post, false)
            .catch(error => {
                console.error(error);
            })
        },

        removeHorseCategorie: async function(horse_id, categorie_id) {
            const params = {
                id: horse_id,
            }
            const del = {
                categorie_id: categorie_id,
            }

            const url = this.constructRoute(Constants.HORSE_CATEGORIE, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_delete_api("HorseMixin::removeHorseCategorie", url, del, false)
            .catch(error => {
                console.error(error);
            })
        },

        addCategorie: async function(categorie_libelle) {
            const post = {
                categorie_libelle: categorie_libelle,
            }

            const url = this.constructRoute(Constants.HORSE_ALL_CATEGORIES, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("HorseMixin::addCategorie", url, post, false)
            .catch(error => {
                console.error(error)
                return null
            })
        },

        updateCategorie: async function(categorie_id, categorie_libelle) {
            const post = {
                categorie_libelle: categorie_libelle,
            }

            const url = this.constructRoute(Constants.HORSE_UPDATE_CATEGORIE, { categorie_id: categorie_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("HorseMixin::updateCategorie", url, post, false)
        },

        removeCategorie: async function(categorie_id) {
            const del = {
                categorie_id: categorie_id,
            }

            const url = this.constructRoute(Constants.HORSE_ALL_CATEGORIES, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_delete_api("HorseMixin::removeCategorie", url, del, false)
            .catch(error => {
                console.error(error);
            })
        },

        updateHorseTranspondeur: async function(horse_id, transpondeur) {
            const params = {
                licence_key: Constants.USER_LICENCE_KEY, // on peut passer aussi des params en post, plutot qu'en full get dégueu
                horse_transpondeur: transpondeur
            }

            const url = this.constructRoute(Constants.HORSE_TRANSPONDEUR_URL, { horse_id: horse_id })
            return await this.$request.request_post_api("HorseMixin::updateHorseTranspondeur", url, params, false)
            .catch(error => {
                console.error(error)
                return null
            })
        },

        updateHorseTaille: async function(horse_id, horse_taille) {
            const params = {
                licence_key: Constants.USER_LICENCE_KEY, // on peut passer aussi des params en post, plutot qu'en full get dégueu
                horse_taille: horse_taille
            }

            const url = this.constructRoute(Constants.HORSE_TAILLE_URL, { horse_id: horse_id })
            return await this.$request.request_post_api("HorseMixin::updateHorseTaille", url, params, false)
            .catch(error => {
                console.error(error)
                return null
            })
        },

        uploadMedia (file, horse_id) {
            let formData = new FormData()
            formData.append('licence_key', Constants.USER_LICENCE_KEY)
            formData.append('file', file)

            const url = this.constructRoute(Constants.HORSE_MEDIAS_URL, { horse_id: horse_id })
            return this.$request.request_post_api("HorseMixin::uploadMedia", url, formData, false)
        },

        search_sire_horse_manual: async function(input, horse_id){

            let url = Constants.SEARCH_SIRE_URL + '?sire='+input+'&horse_id='+horse_id+'&valide=1&update=1&licence_key='+Constants.USER_LICENCE_KEY

            const response = await this.$request.request_get_api("HorseMixin::search_sire_horse_manual", url).catch(error => {
                console.error("HorseMixin::search_sire_horse_manual => ERROR", error)
                this.failureToast("toast.info_save_failed")
                return response.code_retour
            })


            if(response.code_retour === 0) {
                if(response.retour) {
                    return response
                }
            }
            else {
                return response.code_retour
            }
        },


        update_pedigree_horse_manual: async function(horse_id,params){

            const url = this.constructRoute(Constants.HORSE_PEDIGREE_URL, {id: horse_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("HorseMixin::update_pedigree_horse_manual", url, params, false)
            .catch(error => {
                console.error("HorseMixin::update_pedigree_horse_manual => ERROR", error)
                this.failureToast("toast.info_save_failed")
                return null
            })

            return result
        },

        loadHorseTiers: async function(horse_id) {
            const params = { 'horse_id': horse_id }
            const url = this.constructRoute(Constants.HORSE_TIERS_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("HorseMixin::loadTiers", url)
            .catch(error => {
                console.error("HorseMixin::loadTiers => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        loadTiersCurrentPart: async function(horse_id) {
            const params = { 'horse_id': horse_id }
            const url = this.constructRoute(Constants.HORSE_TIERS_CURRENT_PART_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("HorseMixin::loadTiersCurrentPart", url)
            .catch(error => {
                console.error("HorseMixin::loadTiersCurrentPart => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        loadTiersPart: async function(horse_id) {
            const params = { 'horse_id': horse_id }
            const url = this.constructRoute(Constants.HORSE_TIERS_PART_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("HorseMixin::loadTiersPart", url)
            .catch(error => {
                console.error("HorseMixin::loadTiersPart => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        // Ajoute un lien horse/tiers
        addHorseTiers: async function(horse_id, date, tiers) {
            const params = { 'horse_id': horse_id }
            const url = this.constructRoute(Constants.HORSE_TIERS_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const infos = {
                "tiers": tiers,
                "date": date
            }

            const response = await this.$request.request_post_api("HorseMixin::addHorseTiers", url, infos, false)
            .catch(e => {
                console.error("HorseMixin::addHorseTiers", e)
                this.failureToast("toast.info_save_failed")
            })

            return response
        },

        deleteHorseTiers: async function(horse_id, tiers_id, tiershorse_id) {
            const url = this.constructRoute(Constants.HORSE_TIERS_DEL_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_delete_api("HorseMixin::deleteHorseTiers", url, { 'horse_id': horse_id, 'tiers_id': tiers_id, 'tiershorse_id': tiershorse_id})
        },

        deleteHorseTiersPart: async function(tiershorsepart_id) {
            const url = this.constructRoute(Constants.HORSE_TIERS_PART_DEL_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_delete_api("HorseMixin::deleteHorseTiersPart", url, { 'tiershorsepart_id': tiershorsepart_id})
        },

        loadHorsePension: async function(horse_id) {
            const params = { 'horse_id': horse_id }
            const url = this.constructRoute(Constants.HORSE_PENSION_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("HorseMixin::loadPension", url)
            .catch(error => {
                console.error("HorseMixin::loadPension => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        loadCurrentPension: async function(horse_id) {
            const params = { 'horse_id': horse_id }
            const url = this.constructRoute(Constants.HORSE_CURRENT_PENSION_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("HorseMixin::loadCurrentPension", url)
            .catch(error => {
                console.error("HorseMixin::loadCurrentPension => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        // MOUVEMENTS
        // goToAddMouvement(horses = []) {
        //     let params = { from: this.$route.name }
        //     params = this.constructHorseRouteParams(horses, params)
        //     this.$router.push({ name: 'mouvementAjout', params : params })
        // },
        // goToEditMouvement(mouvements) {
        //     const horse_id = this.$route.params.horse_id
        //     const mouvement_ids = this.constructRouteParams(mouvements, 'mouvement_id')
        //     let params = { mouvement_id: mouvement_ids, from: this.$route.name }
        //
        //     if(horse_id) {
        //         params.horse_id = horse_id
        //     }
        //
        //     this.$router.push({ name: 'WriteMouvement', params : params })
        // },

        // ORDONNANCES
        // goToAddOrdonnance(horses = []) {
        //     let params = { from: this.$route.name }
        //     params = this.constructHorseRouteParams(horses, params)
        //     this.$router.push({ name: 'ordonnanceAjout', params : params })
        // },

        duplicateHorseLicence: async function(horse_id) {
            const params = {horse_id}
            const url = this.constructRoute(Constants.HORSE_DUPLICATE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("HorseMixin::duplicateHorseLicence", url, {}, false)
            .catch(error => {
                console.error(error);
            })
        },

        addHorseTiersLicence: async function(horse_id) {
            const params = {horse_id: horse_id}
            const url = this.constructRoute(Constants.HORSE_TIERS_LICENCE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("HorseMixin::duplicateHorseLicence", url, {}, false)
            .catch(error => {
                console.error(error);
            })
        },

        loadJuments: async function(user = false){
            const url = user ? Constants.HORSE_LIST_USER_URL : Constants.HORSE_LIST_URL
            const result = await this.$request.request_get_api("HorseMixin::loadJuments::", url+'?horse_sexe=F&licence_key='+Constants.USER_LICENCE_KEY)
            .catch(e => {
                return null
            })

            if(result) return result.retour
            return []
        },

		getHorseChildren: async function(horse_id) {
			const params = {horse_id: horse_id}
            const url = this.constructRoute(Constants.HORSE_PRODUCT, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            let result = await this.$request.request_get_api("HorseMixin::getHorseChildren", url)
            .catch(error => {
				return []
            })
			
			if(result.retour) return result.retour
			return []
		},

        getMareMonteInfos: async function(horse_id) {
            const url = this.constructRoute(Constants.HORSE_MONTE_INFOS_URL, { horse_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            return this.$request.request_get_api("HorseMixin::getMareMonteInfos::", url)
                .then(res => res.retour)
                .catch(_ => {
                    return null
                })
        },

        getHorseDepart: async function(horse_id) {
            const url = this.constructRoute(Constants.HORSE_DEPARTS_URL, { horse_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            return this.$request.request_get_api("HorseMixin::getHorseDepart", url)
                .then(res => {
                    return res.retour
                })
                .catch(e => {
                    console.error("HorseMixin::getHorseDepart => ERROR", e)
                    return []
                })
        },

        getHorseResultats: async function(horse_id) {
            const url = this.constructRoute(Constants.HORSE_RESULTATS_URL, { horse_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            return this.$request.request_get_api("HorseMixin::getHorseResultats", url)
                .then(res => {
                    return res.retour
                })
                .catch(e => {
                    console.error("HorseMixin::getHorseResultats => ERROR", e)
                    return []
                })
        },

        hideHorseFromCA: async function(params) {
            const url = this.constructRoute(Constants.HORSE_HIDE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("HorseMixin::hideHorse", url)
            .catch(error => {
                console.error("HorseMixin::hideHorse => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        addHorseManuel: async function(params) {
            const url = Constants.HORSE_LIST_URL + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("HorseMixin::addHorseManuel", url, params, false)
        },

        checkPotentialError: function(input) {
            // On va tester les différentes regex d'erreurs - on test de la plus simple à la plus restrictive
            let code_erreur = ''
            /* UELN */
            if(this.regex_ueln_like.test(input)) {

                if(this.regex_incomplete_ueln_car.test(input)) {
                    code_erreur = "LTTL"
                }
                else if(this.regex_incomplete_ueln_cle.test(input)) {
                    code_erreur = "LTKM"
                }
                else if(this.regex_incomplete_ueln_num.test(input)) {
                    code_erreur = "LTTN"
                }
                // else if(this.regex_ueln_error.test(input)) {
                //  error = true
                //  code_erreur = "LTMF"
                // }
                else if(!this.regex_ueln.test(input)) {
                    code_erreur = "LTII"
                }
            }
            /* SIRE */
            else if(this.regex_sire_like.test(input)) {

                if(this.regex_incomplete_sire_cle.test(input)) {
                    code_erreur = "LKIM"
                }
                else if(this.regex_incomplete_sire_num.test(input)) {
                    code_erreur = "LNIM"
                }
                else if(this.regex_incomplete_sire_car.test(input)) {
                    code_erreur = "LTML"
                }
                else if(!this.regex_sire.test(input)) {
                    code_erreur = "LSII"
                }
            }

            return code_erreur
        },

        addHorse: async function(value, type) {
            const url = Constants.HORSE_MANUEL_URL + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("HorseMixin::addHorse", url, {value, type}, false)
            .then(response => {
                return response.retour
            })
            .catch(e => {
                const code_erreur = e.response.data.code_retour ? e.response.data.code_retour : "UKE"
                if(code_erreur == "UKE" && name.length < 8) {
                    return {code: "TMR"}
                }
                else {
                    return {code: code_erreur}
                }
            })
        },

        fetchHorseByNameIfce: async function(value, params = {}) {
            let params_string = ''
            for(let i in params) {
                if(params[i] == null || params[i] == '')
                    continue;
                if(i == 'race')
                    params_string += '&'+i+'='+params[i].race_code
                else if(i == 'sexe')
                    params_string += '&'+i+'='+params[i].sexe_code
                else
                    params_string += '&'+i+'='+params[i]
            }

            const url = Constants.SEARCH_NAME_URL+this.accentsTidy(value.toUpperCase())+"?licence_key="+Constants.USER_LICENCE_KEY + params_string
            return await this.$request.request_get_api("HorseMixin::fetchHorseByNameIfce", url)
            .then(response => {
                let result = []
                if(response.code_retour === 0) {
                    if(response.retour) {
                        result = response.retour
                    }
                }
                return {code: 0, result}
            })
            .catch(e => {
                const code_erreur = e.response.data.code_retour ? e.response.data.code_retour : "UKE"
                if(code_erreur == "UKE" && name.length < 8) {
                    return {code: "TMR"}
                }
                else {
                    return {code: code_erreur}
                }
            })
        },
	}
}

export default HorseMixin
