const Constants = {
    BASE_API_URL:                           process.env.VUE_APP_BASE_API_URL,
	API_URL:                                process.env.VUE_APP_BASE_API_URL + "/api/",
	WEBAPP_GROOMY_URL:						process.env.VUE_APP_GROOMY_WEB_URL,

    // USER
	USER_REDIRECT_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/client_area/redirect/",
	USER_LOGIN:                             process.env.VUE_APP_BASE_API_URL + "/api/client_area/login/",
	USER_AUTOLOGIN:                         process.env.VUE_APP_BASE_API_URL + "/api/client_area/login/autolog",
	USER_VALIDATION_ASK:                    process.env.VUE_APP_BASE_API_URL + "/api/client_area/send_email_verif_ca",
	USER_VALIDATION_EMAIL:                  process.env.VUE_APP_BASE_API_URL + "/api/email/verify/:user_id/:token?:signature",
    USER_CREATE:                            process.env.VUE_APP_BASE_API_URL + "/api/register",
    USER_LOGOUT:                            process.env.VUE_APP_BASE_API_URL + "/api/logout/",
    USER_CA_DETAILS:                        process.env.VUE_APP_BASE_API_URL + "/api/client_area/user/ca_config",
    RESET_PWD_URL_API:                      process.env.VUE_APP_BASE_API_URL + "/api/password/reset/",
    CHECK_EMAIL_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/email/check",
    USER_SEND_AUTOLOG:                      process.env.VUE_APP_BASE_API_URL + "/api/client_area/autolog/send",
    PASSWORD_URL:                           process.env.VUE_APP_BASE_API_URL + "/api/client_area/password",

    // CONFIG
    GENERAL_CONFIG:                         process.env.VUE_APP_BASE_API_URL + "/api/client_area/user/all_config",
	USER_SET_LANG:                          process.env.VUE_APP_BASE_API_URL + "/api/private/config/lang",
    GET_TRANSLATION:                        process.env.VUE_APP_BASE_API_URL + "/api/client_area/translation",
	
	// HORSES
	HORSE_MANUEL_URL:						process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/",
	HORSE_LIST_URL:							process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/",
	HORSE_USER_LIST_URL:				    process.env.VUE_APP_BASE_API_URL + "/api/client_area/user_horses",
	HORSE_URL:								process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id",
    HORSE_MORT_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id/mort",
    HORSE_ACTUAL_PLACE_URL:					process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id/actual_place",
	HORSE_ACTES_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id/actes",
	HORSE_ALL_CATEGORIES:					process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/categories",
	HORSE_CATEGORIES:                       process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id/categories",
	HORSE_PEDIGREE_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id/pedigree",
	HORSE_PEDIGREE_SIRE_URL:                process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_sire/pedigree_sire",
    SIRE_DECODE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/client_area/add_sire_encode/",
    DECODE_SIRE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/client_area/decode_sire/",
    VALIDE_HORSE_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/client_area/valide_horse_web/",
    VALIDE_HORSE_PEDIGREE_URL:              process.env.VUE_APP_BASE_API_URL + "/api/client_area/valide_horse_pedigree_web/",
    SEARCH_SIRE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/client_area/search_sire/",
    SEARCH_UELN_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/client_area/search_ueln/",
    HORSE_DUPLICATE_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/client_area/duplicate_horse/:horse_id",
    HORSE_TIERS_LICENCE_URL:                process.env.VUE_APP_BASE_API_URL + "/api/client_area/tiers_horse/:horse_id",
    STALLION_SEASON_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/stallion/:season_id",
    HORSE_CONTRACT_CONFIG_URL:              process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id/contract_config/:season_id",
	HORSE_PRODUCT:							process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id/get_horse_products",
	HORSE_MONTE_INFOS_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id/monte",
	HORSE_EFFECTIF_URL:						process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/effectif",
	HORSE_DEPARTS_URL:						process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id/partants",
	HORSE_RESULTATS_URL:					process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id/resultats",
    HORSE_HIDE_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_ids/hide",
    HORSE_MOUVEMENTS_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_ids/mouvements",

    // HORSES USER
    SEARCH_NAME_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/liste_nom/",
    SEARCH_NAME_RESTRICT_URL:               process.env.VUE_APP_BASE_API_URL + "/api/client_area/liste_nom_ca/",
    HORSE_LIST_USER_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/horses?query=:search_query",
    SIRE_DECODE_USER_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/add_sire_encode/",
    VALIDE_HORSE_USER_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/valide_horse_web/",

	// ACTES
	ACTES_URL:                              process.env.VUE_APP_BASE_API_URL + "/api/client_area/actes?start=:start&end=:end",
	ACTES_HIST_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/client_area/actes/history?start=:start&end=:end",
	ACTES_NOTIFY_CLIENT:					process.env.VUE_APP_BASE_API_URL + "/api/client_area/actes/notify",
	ACTES_PERFS_PARTANTS_URL:				process.env.VUE_APP_BASE_API_URL + "/api/client_area/actes/partants",
	ACTES_PERFS_RESULTATS_URL:              process.env.VUE_APP_BASE_API_URL + "/api/client_area/actes/resultats",

    // INVOICES
    INVOICES_ADRESSED_TOPAY:                process.env.VUE_APP_BASE_API_URL + "/api/client_area/invoices/addressed/unpaid/",
    INVOICES_ADRESSED_PAID:                 process.env.VUE_APP_BASE_API_URL + "/api/client_area/invoices/addressed/paid/",
    INVOICES_PDF_ADDRESSED:                 process.env.VUE_APP_BASE_API_URL + "/api/client_area/invoices/addressed/:invoice_id/pdf",

    // PAYMENTS
    PAIEMENTS_ADRESSED:                     process.env.VUE_APP_BASE_API_URL + "/api/client_area/invoices/addressed/payment/",

    // DEVIS
    QUOTATIONS_ADRESSED:                    process.env.VUE_APP_BASE_API_URL + "/api/client_area/quotation/addressed/",
    QUOTATIONS_PDF_ADDRESSED:               process.env.VUE_APP_BASE_API_URL + "/api/client_area/quotation/addressed/:quotation_id/pdf",

    // TIERS
    TIERS_URL:                              process.env.VUE_APP_BASE_API_URL + "/api/client_area/tiers/",
    TIERS_LITE_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/client_area/tiers_lite/",
    TIERS_LITE_COUNTRY_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/client_area/tiers_lite_country/",
    TIERS_CMEP_LITE_COUNTRY_URL:            process.env.VUE_APP_BASE_API_URL + "/api/client_area/tiers_cmep_lite_country/:country_code/:postal_code",
    TIERS_COUNTRIES_CMEP:                   process.env.VUE_APP_BASE_API_URL + "/api/client_area/tiers_cmep/countries",
    TIERS_POSTAL_CODES_CMEP:                process.env.VUE_APP_BASE_API_URL + "/api/client_area/tiers_cmep/postal_codes",
	TIERS_LOGGED_URL:						process.env.VUE_APP_BASE_API_URL + "/api/client_area/tiers_logged/",
	TIERS_EDIT_URL:							process.env.VUE_APP_BASE_API_URL + "/api/client_area/tiers/:tiers_id/edit/",

    // CONTRACT
    CONTRACT_TIERS_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/client_area/contract/:contract_id",
    CONTRACT_TIERS_HORSE_URL:               process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id/contract",
    SEASON_LIST_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/client_area/season",
    MODEL_URL:                              process.env.VUE_APP_BASE_API_URL + "/api/client_area/:modelable_type/:modelable_id/model",
    CONTRACT_CONFIG_TYPE_MONTE_URL:         process.env.VUE_APP_BASE_API_URL + "/api/client_area/contract_config/:contractconfig_id/type_monte",
    CONTRACT_TYPE_MONTE_URL:                process.env.VUE_APP_BASE_API_URL + "/api/client_area/type_monte/:typemonte_id/",
    CONTRACT_PRINT_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/client_area/contract/:contract_id/pdf",
    CONTRACT_AUTO_INVOICE_URL:              process.env.VUE_APP_BASE_API_URL + "/api/client_area/contract/:contract_id/auto_invoice",
    CONTRACT_PRIVACY_POLICY_URL:            process.env.VUE_APP_BASE_API_URL + "/api/client_area/contract/privacy_policy",
    CONTRACT_INVOICE_MODE_URL:              process.env.VUE_APP_BASE_API_URL + "/api/client_area/contract/invoice_mode",
    CONTRACT_CONFIG_DEFAULT_CMEP:           process.env.VUE_APP_BASE_API_URL + "/api/client_area/contract_config/:contractconfig_id/default_cmep",
    CONTRACT_MOST_USED_CMEP:                process.env.VUE_APP_BASE_API_URL + "/api/client_area/contract/most_used_cmep",
    CONTRACT_INVOICES_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/client_area/contract/:contract_id/invoices",
    CONTRACT_SIGNATURE_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/client_area/contract/:contract_id/signature",

    //MARKETPLACE
    MARKETPLACE_CHECK_USER_INFOS:           process.env.VUE_APP_BASE_API_URL + "/api/client_area/marketplace/check_user_infos/",
    MARKETPLACE_UPDATE_USER_INFOS:          process.env.VUE_APP_BASE_API_URL + "/api/client_area/marketplace/update_user_infos/",
    MARKETPLACE_PAYMENT_METHOD:             process.env.VUE_APP_BASE_API_URL + "/api/client_area/marketplace/payment_method/",
    MARKETPLACE_ADD_PAYMENT_METHOD:         process.env.VUE_APP_BASE_API_URL + "/api/client_area/marketplace/payment_method/add/",
    MARKETPLACE_CREATE_MANDATE:             process.env.VUE_APP_BASE_API_URL + "/api/client_area/marketplace/mandate/add/",
    MARKETPLACE_CANCEL_MANDATE:             process.env.VUE_APP_BASE_API_URL + "/api/client_area/marketplace/mandate/cancel/",
    MARKETPLACE_SET_PAYMENT_DUEDATE:        process.env.VUE_APP_BASE_API_URL + "/api/client_area/marketplace/duedate/set_payment",
    MARKETPLACE_GET_TYPE:                   process.env.VUE_APP_BASE_API_URL + "/api/client_area/marketplace/type",
    MARKETPLACE_GET_TYPE_NOTAREA:           process.env.VUE_APP_BASE_API_URL + "/api/marketplace/type",
    MARKETPLACE_GET_BOUTIQUE_URL:           process.env.VUE_APP_BASE_API_URL + "/api/client_area/marketplace/boutique",
    MARKETPLACE_GET_BOUTIQUE_DETAILS_URL:   process.env.VUE_APP_BASE_API_URL + "/api/client_area/marketplace/boutique/details",
    MARKETPLACE_GET_BOUTIQUE_CONTRACT_URL:  process.env.VUE_APP_BASE_API_URL + "/api/client_area/marketplace/boutique/contracts",

    // NOTES
	NOTES_URL:								process.env.VUE_APP_BASE_API_URL + "/api/client_area/notes",
    NOTES_FEED_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/client_area/notes/feed",
	NOTES_DETAILS_URL:						process.env.VUE_APP_BASE_API_URL + "/api/client_area/notes/:note_id",
	NOTES_FILES_URL:						process.env.VUE_APP_BASE_API_URL + "/api/client_area/notes/:note_id/files",
	NOTES_FILE_CONTENT_URL:					process.env.VUE_APP_BASE_API_URL + "/api/client_area/notes/:note_id/file/content",
	NOTES_HORSE_SET_URL:					process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id/note",
	NOTES_HORSE_GET_URL:					process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id/notes",
    NOTES_HORSE_FEED_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/client_area/horses/:horse_id/notes/feed",

    // EXPEDITIONS
    EXPEDITIONS_ADRESSED:                   process.env.VUE_APP_BASE_API_URL + "/api/client_area/expeditions",
    EXPEDITIONS_ADRESSED_ID:                process.env.VUE_APP_BASE_API_URL + "/api/client_area/expeditions/:reservations_ids",
    CONTRACT_RESERVATION_URL:               process.env.VUE_APP_BASE_API_URL + "/api/client_area/contract/:contract_id/reservation",

    // CROISSANCE
    HORSE_CROISSANCE:                       process.env.VUE_APP_BASE_API_URL + "/api/client_area/horse/:horse_id/croissance",
    HORSE_CROISSANCE_BY_ID:                 process.env.VUE_APP_BASE_API_URL + "/api/client_area/horse/:horse_id/croissance/:croissance_id",
    HORSE_CROISSANCE_COMPARE:               process.env.VUE_APP_BASE_API_URL + "/api/client_area/horse/:horse_id/croissance/compare/:weeks",

    // GOCARDLESS
    GOCARDLESS_BANK_ACCOUNT:                process.env.VUE_APP_BASE_API_URL + "/api/client_area/gocardless/bank_account",
    GOCARDLESS_MANDATE:                     process.env.VUE_APP_BASE_API_URL + "/api/client_area/gocardless/mandate",
    GOCARDLESS_MANDATE_COMPLETE_ID:         process.env.VUE_APP_BASE_API_URL + "/api/client_area/gocardless/mandate/complete/:mandate_id",
    GOCARDLESS_PAYMENT:                     process.env.VUE_APP_BASE_API_URL + "/api/client_area/gocardless/payment",
    
    //PAX (GFE)
    PAX_STALLIONS:                          process.env.VUE_APP_BASE_API_URL + "/api/client_area/pax/stallions",
    PAX_MARES:                              process.env.VUE_APP_BASE_API_URL + "/api/client_area/pax/mares",
    PAX_CRITERES:                           process.env.VUE_APP_BASE_API_URL + "/api/client_area/pax/criteres",
    PAX_CARACTERISATION_HORSE:              process.env.VUE_APP_BASE_API_URL + "/api/client_area/pax/horse/:horse_id/caracterisation",
    PAX_PANIER_HORSE:                       process.env.VUE_APP_BASE_API_URL + "/api/client_area/pax/horse/:horse_id/panier",
    PAX_GRAPHIQUE_INFOS:                    process.env.VUE_APP_BASE_API_URL + "/api/client_area/pax/graphique/:mare_id/:stallion_id",

    // CRYPTAES
    CRYPTAES_TAB_KEYS: [],

    // INFOS USER
    USER_NAME: "",
    USER_LICENCE_KEY: "",
    USER_LANG_CODE: "",
    USER_ALREADY_LOGGED: false,
    USER_MENU_OPEN: false,
    USER_ACCESS_RIGHTS: '',
    APP_CA_STANDARDS: true
}

export default Constants